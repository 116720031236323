import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'constant';
import { HomeState } from './slice';
import {
  GetStatisticsResponseDto,
  TutorialVideosAndGuideArticles,
} from 'interfaces/home';

export const statisticsSelector = createSelector<
  [(state: RootState) => HomeState],
  GetStatisticsResponseDto | null
>([(state) => state.home], (home) => home.statistics);

export const statisticsLoadingSelector = createSelector<
  [(state: RootState) => HomeState],
  boolean
>([(state) => state.home], (home) => home.statisticsLoading);

export const hideAllPanelsSelector = createSelector<
  [(state: RootState) => HomeState],
  boolean
>([(state) => state.home], (home) => home.hideAllPanels);

export const videosAndArticlesSelector = createSelector<
  [(state: RootState) => HomeState],
  TutorialVideosAndGuideArticles[]
>([(state) => state.home], (home) => home.videosAndArticles);

export const introVideoSelector = createSelector<
  [(state: RootState) => HomeState],
  { [key: string]: string }
>([(state) => state.home], (home) => home.introVideo);

export const loadedVideosAndArticlesSelector = createSelector<
  [(state: RootState) => HomeState],
  boolean
>([(state) => state.home], (home) => home.loadedVideosAndArticles);
